<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                          <div class="card-header actions-toolbar border-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                    <h2 class="d-inline-block mb-0 card-title">人員進出自動辨識</h2>
                                </div>
                            </div>
                          </div>
                          <br/>
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-text-field label="場域名稱" 
                                        v-model="jsName"
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-data-table
                                :headers="headers"
                                :items="datalist"
                                :items-per-page="listPageSize"
                                height="800px"
                                hide-default-footer
                                mobile-breakpoint="961"
                                class="mx-12"> 
                                  <template v-slot:[`item.in`]={item}>
                                    <v-btn v-for="(inItem, index) in item.inoutList" :key="'in' + index" min-width="140" class="ma-1" @click="toInOutPage(item, inItem, 'I')">{{inItem}}</v-btn>
                                  </template>
                                  <template v-slot:[`item.out`]={item}>
                                    <v-btn v-for="(outItem, index) in item.inoutList" :key="'out' + index" min-width="140" class="ma-1" @click="toInOutPage(item, outItem, 'O')">{{outItem}}</v-btn>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import loadingMask from '@/components/public/loadingMask.vue'
import commonMixin from '@/utils/commonMixin';
import Speech from "speak-tts";

import {getJobSiteList} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
        
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
    var Today=new Date();
    this.queryRequest.queryObject.startTrDateStr =  Today.getFullYear()+ "/" + (Today.getMonth()+1) + "/" + Today.getDate();
    this.getDataList();
    this.speech = new Speech();
    this.speech.init();
  },
  computed: {
    headers() {
      return [
          {
            text: '場域名稱',
            align: 'left',
            value: 'jsName',
            class: "subtitle-2 font-weight-black",
          },
          { text: '開始日期', value: 'startDate', class: "subtitle-2 font-weight-black",width:100,},
          { text: '結束日期', value: 'endDate', class: "subtitle-2 font-weight-black",width:100,},
          { text: '辨識入場', value: 'in', class: "subtitle-2 font-weight-black",width:150,},
          { text: '辨識出場', value: 'out', class: "subtitle-2 font-weight-black",width:150,},
      ]
    },
  },
  methods: {
    prepareSpeak: function(text) {
      let vm = this;
      vm.speech.setLanguage("zh-TW");
      vm.speech.speak({
        text: text
      })
      .then((data) => {
        console.log("Success !", data);
      })
      .catch((e) => {
        console.error("An error occurred :", e);
      });
    },
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        console.log("vm.queryRequest = " + JSON.stringify(vm.queryRequest));
        getJobSiteList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                console.log("responseData = " + JSON.stringify(responseData));
                if (responseData.statusCode === "1") {
                    var data = responseData.queryPager.resultList;
                    vm.datalist = data.map(function(item){
                        switch (item.status) {
                        case "Y":
                            item.statusText="啟用";
                            break;
                        case "N":
                            item.statusText="停用";
                            break;
                        default:
                            item.statusText="";
                        }
                        return item;
                    });
                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    toInOutPage: function(data, item, inorout) {
      let vm = this;

      var obj = new Object;
      obj.jsId = data.jsId;
      obj.ioName = item;
      obj.inorout = inorout;

      vm.loginAuth.inout = true;
      window.sessionStorage.setItem('LoginAuth', JSON.stringify(vm.loginAuth));

      if (vm.checkPlusFunc('voiceAlarmEnable')){
        vm.prepareSpeak("辨識開始");
      }
      
      vm.$router.push({
          path: "/inout/inout/" + JSON.stringify(obj)
      });
    },
  },
  watch: {
  },
  data: () => ({
    userName: "",
    isLoading: false, //loading畫面的開關
    auth: {},
    datalist: [],
    jsName: "",
    listPageSize: 999,
    queryRequest: {
        queryObject: {
            jsName: "",
            status: "Y",
            startTrDateStr: null,
        },
        queryPager: {
            currentPage: 1,
            pageSize: 10,
        },
    },
    speech: null,
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>

</style>